<template>
  <div class="clearfix">
    <div class="size-text-11px dark-gray-text semibold-weight text-right bottom-5 right-30">
      {{ notifiedAt }}
    </div>

    <div class="notification">
      <alternative-time-suggested
        v-if="notificationCode === 'alternative_times_suggested'"
        :appointment-metadata="metadata.appointment"
        :show-buttons="showButtons"
        :on-confirm-appointment="onConfirmAppointment">
      </alternative-time-suggested>

      <appointment-requested
        v-if="notificationCode === 'appointment_requested'"
        :appointment-metadata="metadata.appointment"
        :show-buttons="showButtons"
        :on-confirm-appointment="onConfirmAppointment">
      </appointment-requested>

      <availability-sent-confirmation
        v-if="notificationCode === 'availability_sent_confirmation'"
        :appointment-metadata="metadata.appointment"
        :other-participants-list="metadata.otherParticipantsList"
        :show-buttons="showButtons"
        :on-update-availability="onUpdateAvailability">
      </availability-sent-confirmation>

      <appointment-cancelled
        v-if="notificationCode === 'appointment_cancelled'"
        :appointment-metadata="metadata.appointment"
        :other-participants-list="metadata.otherParticipantsList"
        :cancelled-by="metadata.cancelledBy"
        :cancelled-by-me="metadata.cancelledByMe">
      </appointment-cancelled>

      <consultation-completed
        v-if="notificationCode === 'consultation_completed'"
        :possible-lawyer="metadata.possibleLawyer"
        :name-for-clients-reference="metadata.nameForClientsReference"
        :show-success-message="receipt.showSuccessMessage"
        :show-buttons="showButtons"
        :on-request-proposal="requestProposal">
      </consultation-completed>

      <consultation-completed-lawyer
        v-if="notificationCode === 'consultation_completed_lawyer'"
        :appointment-metadata="metadata.appointment"
        :possible-lawyer="metadata.possibleLawyer"
        :counsel-request="metadata.counselRequest"
        :requester="metadata.requester"
        :show-buttons="showButtons">
      </consultation-completed-lawyer>

      <consultation-expired
        v-if="notificationCode === 'consultation_expired'"
        :appointment-metadata="metadata.appointment"
        :other-participants-list="metadata.otherParticipantsList"
        :show-buttons="showButtons"
        :on-update-availability="onUpdateAvailability">
      </consultation-expired>

      <consultation-reschedule-sent
        v-if="notificationCode === 'consultation_reschedule_sent'"
        :appointment-metadata="metadata.appointment"
        :consultation="metadata.consultation"
        :other-participants-list="metadata.otherParticipantsList"
        :show-buttons="showButtons"
        :on-update-availability="onUpdateAvailability">
      </consultation-reschedule-sent>

      <consultation-reschedule-suggested
        v-if="notificationCode === 'consultation_reschedule_suggested'"
        :appointment-metadata="metadata.appointment"
        :consultation="metadata.consultation"
        :participant="metadata.participant"
        :show-buttons="showButtons"
        :on-confirm-appointment="onConfirmAppointment">
      </consultation-reschedule-suggested>

      <consultation-scheduled
        v-if="notificationCode === 'consultation_scheduled'"
        :appointment-metadata="metadata.appointment"
        :consultation="metadata.consultation"
        :other-participants-list="metadata.otherParticipantsList"
        :show-buttons="showButtons"
        :on-reschedule-appointment="onRescheduleAppointment"
        :on-cancel-appointment="onCancelAppointment">
      </consultation-scheduled>

      <last-availability-expired
        v-if="notificationCode === 'last_availability_expired'"
        :appointment-metadata="metadata.appointment"
        :other-participants-list="metadata.otherParticipantsList"
        :show-buttons="showButtons"
        :on-update-availability="onUpdateAvailability">
      </last-availability-expired>
    </div>
  </div>
</template>

<script>
import AlternativeTimeSuggested from 'vue-app/mailboxer/notifications/alternative-time-suggested.vue';
import AppointmentRequested from 'vue-app/mailboxer/notifications/appointment-requested.vue';
import AvailabilitySentConfirmation from 'vue-app/mailboxer/notifications/availability-sent-confirmation.vue';
import AppointmentCancelled from 'vue-app/mailboxer/notifications/appointment-cancelled.vue';
import ConsultationCompleted from 'vue-app/mailboxer/notifications/consultation-completed.vue';
import ConsultationCompletedLawyer from 'vue-app/mailboxer/notifications/consultation-completed-lawyer.vue';
import ConsultationExpired from 'vue-app/mailboxer/notifications/consultation-expired.vue';
import ConsultationRescheduleSent from 'vue-app/mailboxer/notifications/consultation-reschedule-sent.vue';
import ConsultationRescheduleSuggested from 'vue-app/mailboxer/notifications/consultation-reschedule-suggested.vue';
import ConsultationScheduled from 'vue-app/mailboxer/notifications/consultation-scheduled.vue';
import LastAvailabilityExpired from 'vue-app/mailboxer/notifications/last-availability-expired.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'Notification',

  components: {
    AlternativeTimeSuggested,
    AppointmentRequested,
    AvailabilitySentConfirmation,
    AppointmentCancelled,
    ConsultationCompleted,
    ConsultationCompletedLawyer,
    ConsultationExpired,
    ConsultationRescheduleSent,
    ConsultationRescheduleSuggested,
    ConsultationScheduled,
    LastAvailabilityExpired
  },

  props: {
    receipt: {
      type: Object,
      required: true
    },

    onConfirmAppointment: {
      type: Function,
      required: true
    },

    onUpdateAvailability: {
      type: Function,
      required: true
    },

    onCancelAppointment: {
      type: Function,
      required: true
    },

    onRescheduleAppointment: {
      type: Function,
      required: true
    },

    onRequestProposal: {
      type: Function,
      required: true
    }
  },

  computed: {
    message() {
      return this.receipt.message;
    },

    notificationCode() {
      return this.message.notificationCode;
    },

    metadata() {
      return this.message.meta;
    },

    notifiedAt() {
      return DateFilter.shortTime(this.message.createdAt);
    },

    showButtons() {
      return this.message.displayButtons;
    }
  },

  methods: {
    requestProposal(possibleLawyerId) {
      this.onRequestProposal(possibleLawyerId, this.receipt);
    }
  }
};
</script>
