<template>
  <div>
    <messaging-button
      :unread-count="currentUser.unreadMessageCount || 0"
      :on-toggle-messaging="toggleMessaging">
    </messaging-button>

    <div id="messaging-center" ref="messagingCenter" class="messaging-center">
      <action-bar
        :is-refreshing="isRefreshing"
        :on-refresh-messaging="refreshMessaging"
        :on-close-messaging="toggleMessaging">
      </action-bar>

      <div v-if="currentTab === 'conversationList'">
        <conversation-list
          :conversations="conversations"
          :user-class="userClass"
          :on-toggle-contacts="toggleContacts"
          :on-select-conversation="selectConversation">
        </conversation-list>
      </div>

      <div v-if="currentTab === 'contacts'">
        <contact-list
          :on-start-conversation="findOrCreateConversation"
          :on-cancel="deselectConversation">
        </contact-list>
      </div>

      <div v-if="currentTab === 'conversation'" :class="['conversation-container', { 'top-30': isLoadingConversation }]">
        <loading-section name="selectedConversation" :render-after-loading="true">
          <conversation-viewer
            v-if="hasSelectedConversation"
            :conversation="selectedConversation"
            :user-class="userClass"
            :on-create-or-update-message="createOrUpdateMessage"
            :on-deselect="deselectConversation"
            :on-refresh="refreshMessaging"
            :on-archive-conversation="archiveConversation">
          </conversation-viewer>
        </loading-section>
      </div>

      <div v-if="messagingOn" class="close-message-center-button">
        <button type="button" @click="toggleMessaging"><svg-icon name="arrow-right" class="base-icon right-10"></svg-icon></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import MessagingButton from 'vue-app/mailboxer/messaging-button.vue';
import ActionBar from 'vue-app/mailboxer/action-bar.vue';
import ConversationList from 'vue-app/mailboxer/conversation-list.vue';
import ContactList from 'vue-app/mailboxer/contact-list.vue';
import ConversationViewer from 'vue-app/mailboxer/conversation-viewer.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import Conversation from 'resources/marketplace/conversation.js';
import MessagingService from 'services/messaging-service.js';
import PollingService from 'services/polling-service.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';

export default {
  name: 'MessagingCenter',

  components: {
    MessagingButton,
    ActionBar,
    ConversationList,
    ContactList,
    ConversationViewer,
    LoadingSection,
    SvgIcon
  },

  props: {
    userClass: {
      type: String,
      required: true
    },

    openMessaging: {
      type: Boolean,
      default: false
    },

    conversationId: {
      type: [String, Number],
      required: false
    },

    initParams: {
      type: Object,
      default: () => ({})
    },

    ngAppointmentWatcher: {
      tyoe: Object,
      required: false
    }
  },

  data() {
    return {
      messaging: MessagingService,
      currentTab: 'conversationList',
      messagingOn: false,
      isRefreshing: false
    };
  },

  channels: {
    MailboxChannel: {
      received(payload) {
        this.currentUser.unreadMessageCount = payload.unread_message_count;
      }
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    conversations() {
      return this.messaging.conversations;
    },

    selectedConversation() {
      return this.messaging.selectedConversation;
    },

    hasSelectedConversation() {
      return !!this.messaging.selectedConversation;
    },

    isLoadingConversation() {
      return LoadingService.isLoading('selectedConversation');
    }
  },

  // TODO: I'd prefer to do these checks in mounted, but the Angular route
  // doesn't resolve until after this is mounted.
  // Try to move them when we're fully on Vue.
  watch: {
    openMessaging() {
      if (this.openMessaging) { this.toggleMessaging(); }
    },

    conversationId() {
      if (this.conversationId) {
        Conversation.get({ id: this.conversationId })
          .then((conversation) => {
            this.messaging.setConversation(conversation);
            this.messaging.setRead(conversation);
            this.currentTab = 'conversation';
          });
      }
    }
  },

  mounted() {
    // This is temporary to communicate with the lawyer Angular app so it can
    // refresh the UI (in areas other than messaging) when appointments change
    this.messaging.setAngularWatcher(this.ngAppointmentWatcher);

    this.messaging.setUserClass(this.userClass);
    PollingService.startPolling('messaging', this.refreshMessaging);

    this.$cable.subscribe({
      channel: 'MailboxChannel'
    });

    // This should take the place of the separate openMessaging and
    // conversationId props for full Vue
    if (this.initParams.openMessaging) { this.openWhenMounted(); }
  },

  beforeDestroy() {
    PollingService.stopPolling('messaging');
  },

  methods: {
    openWhenMounted() {
      let promise = Promise.resolve();
      const conversationId = this.initParams.conversationId;

      if (conversationId) {
        promise = Conversation.get({ id: conversationId }).then((conversation) => {
          this.messaging.setConversation(conversation);
          this.messaging.setRead(conversation);
          this.currentTab = 'conversation';
        });
      }

      promise.then(() => {
        this.toggleMessaging();
      });
    },

    toggleMessaging() {
      if (this.messagingOn) {
        this.$refs.messagingCenter.classList.remove('on');
        this.$refs.messagingCenter.classList.add('off');
        this.messaging.removeLoader('conversations');

        this.messagingOn = false;
      }
      else {
        this.$refs.messagingCenter.classList.remove('off');
        this.$refs.messagingCenter.classList.add('on');
        this.messaging.addLoader('conversations');
        this.refreshMessaging();

        this.messagingOn = true;
      }
    },

    toggleContacts() {
      this.currentTab = 'contacts';
    },

    refreshMessaging() {
      LoadingService.loading('receipts');
      this.isRefreshing = true;

      return this.messaging.refresh().then(() => {
        this.isRefreshing = false;
        LoadingService.done('receipts');
      });
    },

    selectConversation(conversation) {
      this.currentTab = 'conversation';

      if (this.selectedConversation === conversation) { return; }

      LoadingService.loading('selectedConversation');
      this.messaging.loadConversation(conversation).then(() => {
        LoadingService.done('selectedConversation');
      });
    },

    deselectConversation() {
      this.currentTab = 'conversationList';
      this.$nextTick(() => {
        this.messaging.unloadConversation();
      });
    },

    findOrCreateConversation(conversation) {
      LoadingService.loading('receipts');

      this.currentTab = 'conversation';
      this.messaging.findOrCreateConversation(conversation).then(() => {
        LoadingService.done('receipts');
      });
    },

    createOrUpdateMessage(isSending = false) {
      LoadingService.loading('receipts');

      return this.messaging.createOrUpdateMessage(isSending).finally(() => {
        LoadingService.done('receipts');
      });
    },

    archiveConversation() {
      this.messaging.archiveConversation().then(() => {
        this.currentTab = 'conversationList';
      });
    }
  }
};
</script>
