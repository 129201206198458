<template>
  <div>
    <div class="messaging-center-header-container">
      <div class="messaging-center-header">
        <div>
          Messages
        </div>

        <div class="header-icon icon-right offset" @click="onToggleContacts">
          <svg-icon name="writing" class="base-icon"></svg-icon>
        </div>
      </div>
    </div>

    <div class="messaging-center-content-container previews" v-if="hasConversations">
      <div class="conversation-list-item" v-for="conversation in orderedConversations" :key="conversation.id">
        <conversation-preview
          :conversation="conversation"
          :user-class="userClass"
          :on-select="onSelectConversation">
        </conversation-preview>
      </div>
    </div>

    <div class="messaging-center-content-container" v-else>
      <div class="text-center size-text-13px top-30">
        You have no active conversations.
      </div>
    </div>
  </div>
</template>

<script>
import ConversationPreview from 'vue-app/mailboxer/conversation-preview.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { orderBy } from 'lodash';

export default {
  name: 'ConversationList',

  components: {
    ConversationPreview,
    SvgIcon
  },

  props: {
    conversations: {
      type: Array,
      required: true
    },

    userClass: {
      type: String,
      required: true
    },

    onToggleContacts: {
      type: Function,
      required: true
    },

    onSelectConversation: {
      type: Function,
      required: true
    }
  },

  computed: {
    hasConversations() {
      return this.conversations.length > 0;
    },

    orderedConversations() {
      return orderBy(this.conversations, ['lastMessageSentAt'], ['desc']);
    }
  }
};
</script>

<style lang="scss" scoped>
  .conversation-list-item:last-child {
    margin-bottom: 30px;
  }
</style>
