<template>
  <div class="messaging-center-header-container conversation">
    <div class="messaging-center-header">
      <div class="header-icon icon-left top-10" @click="onDeselect">
        <svg-icon name="arrow-left" class="base-icon"></svg-icon>
      </div>

      <div class="text-center">
        <div class="bottom-10">
          <div class="photo-container single" v-if="participants.length === 1">
            <profile-photo :user="participants[0]" :small="true"></profile-photo>
          </div>

          <div class="photo-container multiple" v-if="participants.length > 1">
            <div class="inner-container first">
              <profile-photo :user="participants[0]" :smallest="true"></profile-photo>
            </div>

            <div class="inner-container second">
              <profile-photo :user="participants[1]" :smallest="true"></profile-photo>
            </div>
          </div>
        </div>

        <div class="size-text-13px text-ellipsis">
          <span v-for="(participant, index) in participantDisplays" :key="`${participant.klass}-${participant.id}`">
            <span class="semibold-weight">{{ participant.name }}{{ (index === participantDisplays.length - 1) || participant.role ? '' : ', ' }}</span>
            <span class="text-italic" v-if="participant.role">({{ participant.role }}){{ (index === participantDisplays.length - 1) ? '' : ', ' }}</span>
          </span>
        </div>
      </div>

      <div class="header-icon icon-right top-10" @click="onViewParticipants">
        <svg-icon name="info" class="base-icon"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';

export default {
  name: 'ConversationHeader',

  components: {
    SvgIcon,
    ProfilePhoto
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    onDeselect: {
      type: Function,
      required: true
    },

    onViewParticipants: {
      type: Function,
      required: true
    }
  },

  computed: {
    participants() {
      return this.conversation.participants;
    },

    participantDisplays() {
      return this.conversation.participantDisplays;
    }
  }
};
</script>
