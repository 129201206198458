<template>
  <div id="conversation-messages" class="messaging-center-content-container messages">
    <loading-section name="receipts" :render-after-loading="true">
      <div v-if="hasReceipts" v-scroll-to-last-message>
        <div class="clearfix" v-for="(receipt, index) in sentReceipts">
          <conversation-message
            :conversation="conversation"
            :receipt="receipt"
            :is-first-message="index === 0"
            :previous-message="sentReceipts[index - 1]?.message"
            :on-confirm-appointment="onConfirmAppointment"
            :on-update-availability="onUpdateAvailability"
            :on-cancel-appointment="onCancelAppointment"
            :on-reschedule-appointment="onRescheduleAppointment"
            :on-request-proposal="onRequestProposal">
          </conversation-message>
        </div>
      </div>
    </loading-section>
  </div>
</template>

<script>
import ConversationMessage from 'vue-app/mailboxer/conversation-message.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ScrollToLastMessage from 'vue-app/mailboxer/directives/scroll-to-last-message.js';

export default {
  name: 'ConversationMessages',

  components: {
    ConversationMessage,
    LoadingSection
  },

  directives: {
    ScrollToLastMessage
  },

  props: {
    conversation: {
      type: Object,
      required: true
    },

    onConfirmAppointment: {
      type: Function,
      required: true
    },

    onUpdateAvailability: {
      type: Function,
      required: true
    },

    onCancelAppointment: {
      type: Function,
      required: true
    },

    onRescheduleAppointment: {
      type: Function,
      required: true
    },

    onRequestProposal: {
      type: Function,
      required: true
    }
  },

  computed: {
    receipts() {
      return this.conversation.receipts || [];
    },

    sentReceipts() {
      return this.receipts.filter(r => !r.message.draft);
    },

    hasReceipts() {
      return this.receipts.some(r => !r.message.draft);
    }
  }
};
</script>
